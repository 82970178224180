<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('添加发票') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            style="width: 100%"
            v-model="formData.invoice_no"
            :placeholder="$t(`发票号码`)"
          />
        </a-col>
        <a-col :span="6">
          <a-input v-model="formData.invoice_code" :placeholder="$t(`发票代码`)" />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            allowClear
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseOrderStart"
            :placeholder="$t(`开票日期`)"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="queryPurchaseSupplierList"
            :params="{
              status: 1,
            }"
            :placeholder="$t(`供应商`)"
            :code.sync="formData.supplierCodeAndName"
            valueName="supplier_code"
            labelName="supplier_name"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-select style="width: 100%" v-model="formData.isAuth" :placeholder="$t('是否认证')">
            <a-select-option :value="1">{{ $t('是') }}</a-select-option>
            <a-select-option :value="0">{{ $t('否') }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            allowClear
            :needhms="false"
            @get_date="chooseAuthDate"
            :placeholder="$t(`认证时间`)"
          />
        </a-col>
        <a-col :span="6">
          <a-select style="width: 100%" v-model="formData.isEntry" :placeholder="$t('是否入账')">
            <a-select-option :value="1">{{ $t('是') }}</a-select-option>
            <a-select-option :value="0">{{ $t('否') }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            allowClear
            :needhms="false"
            @get_date="chooseEntryDate"
            :placeholder="$t(`入账时间`)"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-button type="primary" @click="searchHandle(1)">
            <i class="iconfont icon-search" style="margin-right: 5px" />
            {{ $t(`搜索`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="sync" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content mt10">
        <div class="table-body">
          <CommonTable
            ref="CommonTableRef"
            :statusList="invoice_status"
            :showSelect="true"
            :columns.sync="columns"
            :scroll="{ x: 1500 }"
            :data-source="tableList"
            :selectedRowKeys.sync="selectedRowKeys"
            :loading="loading"
            :total="total"
            rowKey="id"
            @search="getList"
          >
            <template v-slot:header>
              <a-button type="primary" @click="showDateModal('auth')">{{ $t('认证') }}</a-button>
              <OperateBtn
                class="ml10"
                api="cancelAuthInvoice"
                type="danger"
                :text="$t('取消认证')"
                :params="{
                  invoiceIdList: selectedRowKeys,
                }"
                @success="searchHandle"
              />
              <a-button class="ml10" type="primary" @click="showDateModal('entry')">
                {{ $t('入账') }}
              </a-button>
              <OperateBtn
                class="ml10"
                api="cancelEntryInvoice"
                type="danger"
                :text="$t('取消入账')"
                :params="{
                  invoiceIdList: selectedRowKeys,
                }"
                @success="searchHandle"
              />
            </template>
            <template v-slot:invoiceNo="{ text, record }">
              <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
            </template>
            <template v-slot:buyerInfo="{ record }">
              <div>{{ record.buyer_name }} {{ record.buyer_mobile }}</div>
              <div>{{ record.buyer_bank_name }} {{ record.buyer_bank_account }}</div>
            </template>
          </CommonTable>
        </div>
      </div>
    </section>
    <a-modal
      v-model="visible"
      :title="modalType === 'entry' ? $t('入账') : $t('认证')"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item :label="modalType === 'entry' ? $t('入账时间') : $t('认证时间')">
          <DatePicker
            :needhms="false"
            style="background-color: #ffffff; width: 100%"
            :init-date-time="modalDate"
            @choose="chooseDate"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import DateRangePicker from '@component/dateRangePicker'
import CommonTable from '@component/commonTable'
export default {
  name: 'invoiceManage',
  activated() {
    this.getBizType(this.$route.name)
  },
  deactivated() {
    // 组件被停用时执行
    // 可以在这里执行一些清理操作
    // this.$options.watch.$route.teardown()
  },
  watch: {
    $route(to) {
      this.getBizType(to.name)
    },
  },
  components: {
    DatePicker,
    DateRangePicker,
    CommonTable,
  },
  data() {
    return {
      loading: false,
      tableList: [],
      bizType: undefined,
      formData: {
        invoice_no: undefined,
        invoice_code: undefined,
        supplierCodeAndName: undefined,
        startTime: undefined,
        endTime: undefined,
        invoiceDateStart: undefined,
        invoiceDateEnd: undefined,
      },
      total: 0,
      selectedRowKeys: [],
      visible: false,
      modalType: false,
      modalDate: undefined,

      columns: [
        {
          title: this.$t(`发票号码`),
          dataIndex: 'invoice_no',
          scopedSlots: { customRender: 'invoiceNo' },
        },
        {
          title: this.$t(`发票代码`),
          dataIndex: 'invoice_code',
        },
        {
          title: this.$t(`发票金额`),
          dataIndex: 'amount_with_tax',
        },
        {
          title: this.$t(`发票状态`),
          dataIndex: 'status',
          customRender: (text) => this.invoiceStatusMapping[text],
        },
        {
          title: this.$t(`开票时间`),
          dataIndex: 'invoice_date',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t(`认证时间`),
          dataIndex: 'auth_date',
          customRender: (text, record) => (record.is_auth ? text?.split(' ')[0] : ''),
        },
        {
          title: this.$t(`入账时间`),
          dataIndex: 'entry_date',
          customRender: (text, record) => (record.is_entry ? text?.split(' ')[0] : ''),
        },
        {
          title: this.$t(`发票类型`),
          dataIndex: 'invoice_type',
          customRender: (text) => this.invoiceTypeMapping[text],
        },
        // {
        //   title: this.$t(`税率`) + '(%)',
        //   dataIndex: 'tax_rate',
        // },
        {
          title: this.$t(`税额`),
          dataIndex: 'tax_amount',
          customRender: (text) => this.$currencySymbol(text, 'CNY'),
        },
        {
          title: this.$t(`不含税金额`),
          dataIndex: 'amount_without_tax',
          customRender: (text) => this.$currencySymbol(text, 'CNY'),
        },
        {
          title: this.$t(`开票人纳税人识别号`),
          dataIndex: 'buyer_taxpayer_code',
        },
        {
          title: this.$t(`开票人信息`),
          key: 'buyerInfo',
          scopedSlots: { customRender: 'buyerInfo' },
        },
        {
          title: this.$t(`备注`),
          dataIndex: 'remark',
        },
        // {
        //   title: this.$t(`操作`),
        //   width: 120,
        //   fixed: 'right',
        //   scopedSlots: { customRender: 'Action' },
        // },
      ],
    }
  },
  computed: {
    ...mapState(['purchaseStatusList', 'invoice_status']),
    ...mapGetters(['invoiceTypeMapping', 'invoiceStatusMapping']),
    tableHeight() {
      return window.innerHeight - 400
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    getBizType(name) {
      if (name === 'salesInvoiceList') {
        this.bizType = 'SALE'
        this.searchHandle()
      } else if (name === 'purchaseInvoiceList') {
        this.bizType = 'PURCHASE'
        this.searchHandle()
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle()
    },
    getList(params) {
      this.loading = true
      let data = {
        ...this.formData,
        ...params,
        bizTypeList: [this.bizType],
      }
      http({
        type: 'post',
        url: api.getInvoiceList,
        data,
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list
          this.total = res.result.total
        },
      })
    },
    createHandle() {
      this.$router.push({
        name: 'invoiceDetail',
        query: {
          bizType: this.bizType,
        },
      })
    },
    chooseOrderStart(date) {
      this.formData.invoiceDateStart = date.start_time
      this.formData.invoiceDateEnd = date.end_time
    },
    chooseAuthDate(date) {
      this.formData.authDateStart = date.start_time
      this.formData.authDateEnd = date.end_time
    },
    chooseEntryDate(date) {
      this.formData.enrtyDateStart = date.start_time
      this.formData.enrtyDateEnd = date.end_time
    },
    viewDetail(record) {
      this.addPane({
        view: 'invoiceDetail/' + record.invoice_no,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.invoice_no,
        },
        route: `/invoiceManage/invoiceDetail?id=${record.id}&sn=${record.invoice_no}`,
      })
    },
    showDateModal(type) {
      if (this.selectedRowKeys.length === 0) {
        return this.$message.warning(this.$t('请选择发票'))
      }
      this.modalDate = undefined
      this.visible = true
      this.modalType = type
    },
    chooseDate(data) {
      this.modalDate = data.data
    },
    async handleOk() {
      if (!this.modalDate) {
        return this.$message.warning(this.$t('请选择日期'))
      }
      this.modalLoading = true
      const url = this.modalType === 'auth' ? api.authInvoice : api.entryInvoice
      const data =
        this.modalType === 'auth' ? { authDate: this.modalDate } : { entryDate: this.modalDate }
      data.invoiceIdList = this.selectedRowKeys
      await http({
        url,
        data,
        success: (res) => {
          if (res.success) {
            this.$message.success(this.$t('操作成功'))
            this.handleCancel()
            this.searchHandle()
          }
        },
      })
      this.modalLoading = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.ant-form-item {
  margin-bottom: 0;
}
</style>
